import React, { useState } from "react"
import SuccessModal from "../components/headerNav/login/SuccessModal"
import ErrorModal from "../components/headerNav/login/ErrorModal"


const Parse = require('parse/node');

Parse.serverURL = 'https://parseapi.back4app.com'; // This is your Server URL
Parse.initialize(
  'q48tbaunRNR3NLrxAcmQax0i8cB4EDmqezCXUfrb', // This is your Application ID
  'RsXCAqDszEEXOtWb0orP9pj4Q03SJRKEwE0ys5Ju', // This is your Javascript key
);


const dbAPI = {

  async loginUser(userObj) {

    try {
      return await Parse.User.logIn(userObj.username, userObj.password).then(resp => {
        sessionStorage.clear()
        sessionStorage.setItem('userId', resp.id)
        return resp
      });
      // Hooray! Let them use the app now.
    } catch (error) {
      // Show the error message somewhere and let the user try again.
      return alert("Error: " + error.code + " " + error.message);
    }
  },

  async createNewUser(userObj) {

    const User = Parse.Object.extend("User")
    const user = new User();

    user.set(userObj)

    try {
      return await user.signUp().then(resp => {
        sessionStorage.clear()
        sessionStorage.setItem('userId', resp.id)
        return resp
      })
      // Hooray! Let them use the app now.
    } catch (error) {
      // Show the error message somewhere and let the user try again.
      alert("Error: " + error.code + " " + error.message);
    }
  },

  async getUser(userId) {
    const User = Parse.Object.extend('User');
    const query = new Parse.Query(User);
    await query.get(userId)
      .then((user) => {
        console.log(user.attributes)
        return user
      }, (error) => {
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });

  },

  logOut() {
    sessionStorage.clear()
  },

  fetchObjectByClassNameAndId(className, objectId) {
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.equalTo('objectId', objectId)
    return query.find()
      .then((results) => {
        return results[0]
      }, (error) => {
        window.alert(error)
      });
  },

  getAllByClassName(className) {
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    return query.find()
      .then((results) => {
        return results
      }, (error) => {
        window.alert(error)
      });
  },

  createNewObjectByClassName(className, resourceObject) {

    const FetchedResource = Parse.Object.extend(className)
    const newResource = new FetchedResource();

    newResource.set(resourceObject)

    return newResource.save().then((returnedNewResource) => returnedNewResource)
  },

  async getGovernmentsNotSelected() {
    const activeUserId = sessionStorage.getItem('userId')
    const userGovIdArray = []

    const UserGovernments = Parse.Object.extend('UserGovernments');
    const query = new Parse.Query(UserGovernments);

    query.equalTo("userId", activeUserId)
    await query.find().then(results => {
      results.forEach(result => userGovIdArray.push(result.attributes.governmentId))
    })

    const Governments = Parse.Object.extend('Governments');
    const query2 = new Parse.Query(Governments);

    query2.notContainedIn('objectId', userGovIdArray)

    return query2.find().then(resp => resp)

  },

  async getUserGovernments() {
    const activeUserId = sessionStorage.getItem('userId')
    const userGovIdArray = []

    const UserGovernments = Parse.Object.extend('UserGovernments');
    const query = new Parse.Query(UserGovernments);

    query.equalTo("userId", activeUserId)
    await query.find().then(results => {
      results.forEach(result => userGovIdArray.push(result.attributes.governmentId))
    })

    const Governments = Parse.Object.extend('Governments');
    const query2 = new Parse.Query(Governments);

    query2.containedIn('objectId', userGovIdArray)

    return query2.find().then(resp => resp)

  },

  async deleteUserGov(userId, govId) {
    const Class = Parse.Object.extend('UserGovernments');
    const query = new Parse.Query(Class);
    // here you put the objectId that you want to delete

    query.equalTo('userId', userId)
    query.equalTo('governmentId', govId)

    await query.find().then((object) => {
      object[0].destroy().then((response) => {
        if (typeof document !== 'undefined')
          return console.log('Deleted ParseObject', response);
      }, (error) => {
        if (typeof document !== 'undefined') document.write(`Error while deleting ParseObject: ${JSON.stringify(error)}`);
        return console.error('Error while deleting ParseObject', error);
      })

    });

  },

  async deleteObjectByClassNameAndId(className, objectId) {
    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    // here you put the objectId that you want to delete
    await query.get(objectId).then((object) => {
      object.destroy().then((response) => {
        if (typeof document !== 'undefined')
          return console.log('Deleted ParseObject', response);
      }, (error) => {
        if (typeof document !== 'undefined') document.write(`Error while deleting ParseObject: ${JSON.stringify(error)}`);
        return console.error('Error while deleting ParseObject', error);
      });
    });
  },

  getUserContentPerGovernment(govId) {
    const activeUserId = sessionStorage.getItem('userId')

    const governmentOfficials = new Parse.Query(Parse.Object.extend('GovernmentOfficials')).equalTo('userId', activeUserId).equalTo('governmentId', govId).find();
    const departments = new Parse.Query(Parse.Object.extend('Departments')).equalTo('userId', activeUserId).equalTo('governmentId', govId).find();
    const committees = new Parse.Query(Parse.Object.extend('Committees')).equalTo('userId', activeUserId).equalTo('governmentId', govId).find();
    const legislation = new Parse.Query(Parse.Object.extend('Legislation')).equalTo('userId', activeUserId).equalTo('governmentId', govId).find();
    const events = new Parse.Query(Parse.Object.extend('Events')).equalTo('userId', activeUserId).equalTo('governmentId', govId).find();
    const elections = new Parse.Query(Parse.Object.extend('Elections')).equalTo('userId', activeUserId).equalTo('governmentId', govId).find();
    const services = new Parse.Query(Parse.Object.extend('Services')).equalTo('userId', activeUserId).equalTo('governmentId', govId).find();

    return Promise.all([governmentOfficials, departments, committees, legislation, events, elections, services])
  },

  async saveEditedObjectByClassNameAndObjId(className, objectId, updatedObj) {
      const Class = Parse.Object.extend(className);
      const query = new Parse.Query(Class);
    // here you put the objectId that you want to update
      await query.get(objectId).then((object) => {
      object.set(updatedObj);

      object.save().then((response) => {
        // You can use the "get" method to get the value of an attribute
        // Ex: response.get("<ATTRIBUTE_NAME>")
        if (typeof document !== 'undefined'){
          return response
        }
            console.log('Updated ClassObj', response);
            return response
      }, (error) => {
        if (typeof document !== 'undefined') document.write(`Error while updating ClassObj: ${JSON.stringify(error)}`);
         return console.error('Error while updating Class', error);
      });
    });
  }

};


export default dbAPI;